@font-face {
    font-family: 'Montserrat';
    src: url(../../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}


/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */

* {
    font-family: "Montserrat", sans-serif !important;
}

section {
    min-height: 100vh;
}

.min-w-lg {
    min-width: 992px;
}

.min-w-xl {
    min-width: 1200px;
}