#interactiveLogo svg {
    height: 700px;
}

#interactiveLogo path {
    fill: #97917F;
    stroke: #97917F;
    stroke-width: 1.5pt;
    fill-opacity: 0;
    transition: fill-opacity 2s;
}

#interactiveLogo path:hover {
    fill-opacity: 1;
    transition: fill-opacity 0.1s;
}

@media screen and (max-width: 991px) {
    #interactiveLogo svg {
        height: 300px;
    }
}