#deployment {
    background-image: url('../../public/img/logo_gray.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

#deployment .top-title {
    font-size: 64pt;
    font-weight: bolder;
}

#deployment .top-title {
    color: #333333;
}

#deployment .top-title .highlight {
    color: #B99A80;
}

#deployment .item-title {
    font-size: 32pt;
    color: #B99A80;
}

#deployment .item-content {
    font-size: 24pt;
}

@media screen and (max-width: 576px) {
    #deployment .top-title {
        font-size: 32pt;
    }

    #deployment .item-title {
        font-size: 24pt;
    }

    #deployment .item-content {
        font-size: 18pt;
    }
}