#subscribe #interactiveLogo svg {
    height: 400px;
}
#subscribe .bottom-logo{
    width: 400px;
}

#subscribe .bg-mm-secondary {
    background-color: #DCBFA5;
}

#subscribe .subscribe-form .subscribe-title {
    font-size: 24pt;
    font-weight: bolder;
    color: #473F39;
}

#subscribe .subscribe-form .subscribe-text {
    font-size: 12pt;
    color: #473F39;
}

#subscribe .subscribe-form .btn-subscribe {
    font-size: 22px;
    color: #FFFFFF;
    background-color: #816D5B;
    height: 50px;
    border-radius: 50px;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
}

#subscribe .subscribe-form .btn-subscribe:hover {
    background-color: #FFFFFF;
    color: #816D5B;
}

@media screen and (max-width: 576px) {
    #subscribe .subscribe-form .subscribe-title {
        font-size: 16pt;
    }
    #subscribe .subscribe-form .subscribe-text {
        font-size: 10pt;
    }
}