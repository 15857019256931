#fields {
    background-image: url('../../public/img/fields/bg.webp');
    background-size: cover;
}

#fields .top-title {
    font-size: 64pt;
    font-weight: bolder;
}

#fields .field {
    background-color: rgba(0, 0, 0, 0.6);
    height: 480px;
}

#fields .field:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

#fields .field .icon {
    display: none;
    font-size: 28px;
}

#fields .field .action {
    display: block;
    /* background-color: #DB6320; */
    background-color: #c14b0a;
    padding: 4px 10px 4px 10px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1200px) {
    #fields .field .action {
        display: none;
        margin-left: unset;
        margin-right: unset;
    }

    #fields .field:hover .action {
        display: inline;
    }

    #fields .field .icon {
        display: block;
    }

    #fields .field:hover .icon {
        display: none;
    }

}


@media screen and (max-width: 576px) {
    #fields .top-title {
        font-size: 28pt;
    }

    #fields .field:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }
}