#topbar {
    border-bottom: 1px solid #B99A80;
}

#topbar .top-logo {
    max-height: 42px;
}

#topbar ul.menu-options .divider {
    font-size: 15px;
    padding-top: 2px;
    color: #F5F5F5;
}

#topbar li.menu-option {
    font-size: 16px;
    color: #F5F5F5;
    white-space: nowrap;
}

#topbar li.menu-option.active {
    color: #C2A388;
}

#topbar .request-offer {
    background-color: #B99A80;
    border: 1px solid #B99A80;
    border-bottom: none;
    color: #333333;
    transition: background-color 0.5s ease, color 0.5s ease;
}

#topbar .request-offer:hover, #topbar .request-offer.current  {
    color: #B99A80;
    background-color: rgba(185, 154, 128, 0);
}

#topbar .request-offer .send-icon {
    font-size: 18px;
}

@media screen and (max-width: 1200px) {

    #topbar li.menu-option,
    #topbar div.request-offer {
        font-size: 12px;
    }

    #topbar ul.menu-options .divider {
        font-size: 12px;
    }

    #topbar .top-logo {
        max-height: 38px;
    }

}