#error {
    background-color: #473F39;
    height: 100vh;
    text-align: center;
}

#error p {
    color: #F3F3F3;
}

#error .section-title {
    font-size: 64px;
    font-weight: light;
    top: -3.3rem;
    color: #B99A80;
}
#error .section-subtitle {
    font-size: 32px;
    font-weight: light;
    top: -3.3rem;
    color: #B99A80;
}