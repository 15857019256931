#footer {
    height: 400px;
    background-color: black;
}

#footer .image-container img {
    max-width: 300px;
}

#footer .social a {
    font-size: 24px;
    color: #E5D5C6;
}

#footer .copyright {
    font-size: 18px;
    color: #E5D5C6;
}