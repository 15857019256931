#partners {
    background-image: url("../../public/img/logo_gray.svg");
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: center;

}

#partners h1 {
    color: #4D4642;
}

#partners .partner-logo {
    background-color: black;
    width: 400px;
    height: 140px;
    text-align: center;
}

#partners .partner-logo img {
    max-width: 300px;
}