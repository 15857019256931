#services {
    background-color: #F9F9F9;
}

#services .top-title {
    font-size: 64pt;
    font-weight: bolder;
    color: #616161;
}

#services .watermark-container img {
    bottom: -250px;
}

#services .h3 {
    color: #535353;
}

#services .tab-option {
    transition: margin-bottom 0.05s linear;
    ;
}

#services .description {
    transition: height 0.05s linear;
    /* TODO */
}

#services .tab-image {
    max-width: 80%;
}

#services .service-description-container {
    min-height: 400px;
    display: table;
}

#services .service-description-container p {
    vertical-align: middle;
    display: table-cell;
}

@media screen and (max-width: 576px) {
    #services .top-title {
        font-size: 28pt;
    }

    #services .tab-image {
        max-width: 180px;
    }
}

@media screen and (max-width: 768px) {
    #services .description {
        border-top: 1px solid rgb(233,236,239);
    }
}

@media screen and (min-width: 769px) {
    #services .service-description-container {
        min-height: 250px;
    }
}