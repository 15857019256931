#process .top-title {
    font-size: 64pt;
    font-weight: bolder;
    color: #616161;
}

#process .watermark-container img {
    top: -530px;
}

#process .lets-get-started {
    border-radius: 38px;
    color: #333333;
    border: none;
    transition: background-color 0.5s ease;
    font-weight: bold;
}

#process .lets-get-started:hover {
    background-color: #E5D5C6;
}

#process .btn-secondary {
    --bs-btn-bg: #F9F9F9;
    color: #616161;
}

@media screen and (max-width: 576px) {
    #process .top-title {
        font-size: 28pt;
    }
}