#about {
    background-image: url('../../public/img/about/bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: grayscale(90%);
}

#about .top-title {
    font-size: 64pt;
    font-weight: bolder;
}

#about .top-title .about {
    color: #3e3e3e;
}

#about .section-field {
    background-color: rgba(255, 255, 255, 0.6);
}

#about .section-field>.section-title {
    font-size: 64px;
    font-weight: light;
    top: -3.3rem;
    color: #535353;
}


#contact .top-title {
    font-size: 32pt;
    color: #473F39;
}

#contact .top-title .gold {
    color: #B99A80;
}

#contact .langs-text {
    color: #473F39;
    background-color: rgba(255, 255, 255, 0.8);
}

#contact .languages {
    opacity: 0.5;
    z-index: -1;
}

#contact .languages img {
    max-width: 200px;
}

#contact .contact-form {
    background-color: #F5F5F5;
}

#contact .contact-form .contact-title {
    font-size: 32pt;
    font-weight: bolder;
    color: #473F39;
}


@media screen and (max-width: 576px) {
    #about {
        background-color: darkgray;
    }

    #about .top-title,
    #contact .top-title {
        font-size: 28pt;
    }


    #about .section-field>.section-title {
        font-size: 32px;
        top: -1.6rem;
    }

    #contact .contact-form .contact-title {
        font-size: 16pt;
    }

    #contact .languages img {
        max-width: 150px;
    }
}