#home .home-text {
    font-size: 32pt;
    font-weight: bold;
    color: #F5F5F5;
}

#home .home-text .we-create {
    color: #B99A80;
}

#home .godown {
    animation: oscillate 2s linear infinite;
}

@keyframes oscillate {
    0% {
        bottom: 0px;
    }

    25% {
        bottom: -10px;
    }

    50% {
        bottom: 0px;
    }

    75% {
        bottom: 10px;
    }

    100% {
        bottom: 0px;
    }

}

@media screen and (max-width: 576px) {
    #home .home-text {
        font-size: 18pt;
    }
}

@media screen and (max-width: 992px) and (min-width: 576px) {
    #home .home-text {
        font-size: 24pt;
    }
}

#home .request-offer {
    background-color: #B99A80;
    border: 1px solid #B99A80;
    color: #333333;
    transition: background-color 0.5s ease, color 0.5s ease;
    width: 60%;
}

#home .request-offer:hover, #topbar .request-offer.current  {
    color: #B99A80;
    background-color: rgba(185, 154, 128, 0);
}

#home .request-offer .send-icon {
    font-size: 18px;
}