#offer .top-title {
    font-size: 32pt;
    font-weight: bolder;
}

#offer .top-title {
    color: #FFFFFF;
}

#offer .highlight, #offer .highlight a {
    color: #B99A80;
    text-decoration: unset;
}

#offer .item-title {
    font-size: 32pt;
    color: #B99A80;
}

#offer .item-content {
    font-size: 24pt;
}


#offer .contact-form {
    background-color: #F5F5F5;
}

#offer .contact-form .contact-title {
    font-size: 32pt;
    font-weight: bolder;
    color: #473F39;
}
